<template>
  <section>
    <h1 class="bold-header-text app-header mb-3"><span>Change Requests </span></h1>
    <b-row class="mb-2">
      <b-col md="12">
        <p style="margin-top: 1rem">
          The options below are for companies that are currently accredited and need to make changes
          to their company information. Addditional information and fees may be required.
        </p>
        <div id="card-block">
          <achc-collapsible-card
            v-for="(category, index) in resources"
            :key="`category_${index}`"
            :card-visible="false"
          >
            <template slot="header">
              <h2 class="resource-program-header">{{ category.category }}</h2>
            </template>
            <template slot="body">
              <div>
                <p>{{ handleInstruction(category.category) }}</p>
              </div>
              <div class="container">
                <div v-for="resource in category.resources" :key="resource.resourceID">
                  <ul>
                    <li>
                      <a
                        class="resource-link"
                        href="javascript:void(0)"
                        :title="resource.title"
                        v-on:click="($event) => downloadResource(resource)"
                      >
                        {{ resource.title }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
          </achc-collapsible-card>
        </div>
      </b-col>
    </b-row>
  </section>
</template>
<style lang="scss" scoped>
#card-block {
  margin-left: 1rem;
}
</style>
<script>
import CollapsibleCard from "../../../components/Common/CollapsibleCard.vue";
import ResourceMixin from "../../../mixins/ResourceMixin.vue";
import { WebAPIUrl } from "../../../constants/config";
import { bus } from "../../../main";

export default {
  components: {
    "achc-collapsible-card": CollapsibleCard,
  },
  data() {
    return {
      controlledDocUrl: `${WebAPIUrl}/api/navigator-resource/controlled-doc`,
      catlist: [],
    };
  },
  async created() {
    bus.$emit("showFullpageOverlay", {
      showLayover: true,
    });
    await this.getChangeRequestResources();
    bus.$emit("showFullpageOverlay", {
      showLayover: false,
    });
  },
  mixins: [ResourceMixin],
  methods: {
    handleInstruction(category) {
      switch (category) {
        case "Name Change":
          return "Please complete this form if your agency has changed or added Legal or DBA names.";
        case "Additional Site Location":
          return "Please complete this form if you would like to add an additional location to your organization. Examples include: branch, multiple location, satellite location, drop-site, warehouse, call center, etc.";
        case "Change of Location":
          return "Please complete this form if your agency has moved locations or to update your mailing or accounting address on file.";
        case "Change Product Codes":
          return "Please complete this form to add or remove product codes to your accreditation.";
        case "Change Services":
          return "Please complete this form to add or remove services associated with your accreditation.";
        case "Change of Ownership":
          return "Please complete this form if your organization has gone through an ownership change. Please contact your Account Advisor if you have any questions on what qualifies as a change of ownership.";
        case "Change of Personnel":
          return "Please complete this form if key personnel have changed within your organization. Please see the form below for a list of key personnel.";
        case "Closure/Withdrawal Notification":
          return "Please complete this form if there is a closure of your organization or if you would like to withdraw your agency’s accreditation.";
        default:
          return "";
      }
    },
    getResourceURL(resource) {
      if (resource.controlledDocID === null) {
        return resource.url;
      }

      return `${this.controlledDocUrl}/${resource.controlledDocID}`;
    },
    async downloadResource(resource) {
      const url = this.getResourceURL(resource);
      await this.downloadResourceFile(url, `${resource.controlledDocID}.pdf`);
    },
  },
};
</script>
